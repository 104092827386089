












































































import { Vue, Component } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { ContractType, CrewMemberModel } from "@/api/generated";
import nameof from "@/utility/nameof";
import UserModule from "@/store/userModule";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import {inviteUserToApp, allowUserOnApp, blockAppAccess} from "@/utility/inviteUserToApp";
import InviteToAppMenuItem from '../../../components/common/menu-items/InviteToAppMenuItem.vue';
import MenuWrapper from "@/components/common/menu-items/MenuWrapper.vue";
import LandWorkerMenuItems from "@/components/common/menu-items/Groups/LandWorkerMenuItems.vue";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: {
    InviteToAppMenuItem,
    MenuWrapper,
    LandWorkerMenuItems
  }
})
export default class CrewMemberTable extends Vue {
  public loading = false;
  private crewMembers: Array<CrewMemberModel> = [];
  private search = "";
  private headers: Array<DataTableHeader> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<CrewMemberModel>("fullname") },
    { text: "Known As", value: nameof<CrewMemberModel>("knownAs") },
    { text: "Rating", value: nameof<CrewMemberModel>("rating") },
    {
      text: "Nationality",
      value: nameof<CrewMemberModel>("countryNationality")
    },
    { text: "Age", value: nameof<CrewMemberModel>("dateOfBirth") },
    {
      text: "Current Company",
      value: nameof<CrewMemberModel>("currentVessel")
    },
    {
      text: "Agency",
      value: nameof<CrewMemberModel>("agencyName")
    },
    {
      text: "Passport Expiry",
      value: nameof<CrewMemberModel>("passportExpiry")
    },
    {
      text: "Date of Termination",
      value: nameof<CrewMemberModel>("employmentEndDate")
    },
    {
      text: "Last Note",
      value: nameof<CrewMemberModel>("lastNote")
    },
    { text: "App Access", value: nameof<CrewMemberModel>("linkedUser") },
    { text: "Active", value: nameof<CrewMemberModel>("active") },
    { text: "JobRole", value: nameof<CrewMemberModel>("crewJobRoles") },
    { text: "", value: nameof<CrewMemberModel>("doNotUse") }
  ];
  private agencyAdminHeaders: Array<DataTableHeader> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<CrewMemberModel>("fullname") },
    { text: "Known As", value: nameof<CrewMemberModel>("knownAs") },
    { text: "Rating", value: nameof<CrewMemberModel>("rating") },
    {
      text: "Nationality",
      value: nameof<CrewMemberModel>("countryNationality")
    },
    { text: "Age", value: nameof<CrewMemberModel>("dateOfBirth") },
    {
      text: "Current Company",
      value: nameof<CrewMemberModel>("currentVessel")
    },
    {
      text: "Passport Expiry",
      value: nameof<CrewMemberModel>("passportExpiry")
    },
    {
      text: "Date of Termination",
      value: nameof<CrewMemberModel>("employmentEndDate")
    },
    {
      text: "Last Note",
      value: nameof<CrewMemberModel>("lastNote")
    },
    { text: "Active", value: nameof<CrewMemberModel>("active") },
    { text: "JobRole", value: nameof<CrewMemberModel>("crewJobRoles") },
    { text: "", value: nameof<CrewMemberModel>("doNotUse") }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private get tableHeaders() {
    if (userModule.role == "AGM") {
      return this.agencyAdminHeaders;
    } else {
      return this.headers;
    }
  }

  private async created() {
    await this.getCrewMembers();
  }

  private async getCrewMembers() {
    try {
      this.loading = true;
      const response = await Api.CrewMemberService.apiCrewmemberDiscriminatorGet(ContractType.NUMBER_1);
      this.crewMembers = response.data.filter(
        x => !x.active
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve land workers");
    } finally {
      this.loading = false;
    }
  }

  public updateCrewMemberLocally(item: CrewMemberModel) {
    const index = this.crewMembers.findIndex(member => member.crewMemberId === item.crewMemberId);

    if(index) {
      this.crewMembers[index] = item;
    } else {
      this.getCrewMembers();
    }
  }

  private onCreateCrewMember() {
    this.$router.push({ name: "Land Worker Create" });
  }

  private async onDeleteCrewMember(crewMember: CrewMemberModel) {
    if (!crewMember.crewMemberId) {
      return;
    }

    try {
      await Api.CrewMemberService.apiCrewmemberCrewMemberIdDelete(
        crewMember.crewMemberId
      );
      snackbarModule.setSnackbarMessage(`Land worker successfully deleted`);
    } catch {
      snackbarModule.setSnackbarMessage(`Failed to delete land worker`);
    }

    await this.getCrewMembers();
  }

  private onUpdateCrewMember(crewMember: CrewMemberModel) {
    if (!crewMember.crewMemberId) {
      return;
    }

    if (userModule.role == "AGM") {
      this.$router.push({
        name: "Crew Member Edit Agency",
        params: { crewMemberId: crewMember.crewMemberId }
      });
    } else {
      this.$router.push({
        name: "Land Worker Edit",
        params: { crewMemberId: crewMember.crewMemberId }
      });
    }
  }

  private async updateDoNotUse(item: CrewMemberModel) {
    try {
      if (item.crewMemberId) {
        await Api.CrewMemberService.apiCrewmemberCrewMemberIdPut(
          item.crewMemberId,
          item
        );
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update user to Do not use");
    } finally {
      await this.getCrewMembers();
    }
  }
}






















import Vue from "vue";
import InviteToAppMenuItem from "@/components/common/menu-items/InviteToAppMenuItem.vue";
import GDPRMenuItems from "@/components/common/menu-items/Groups/GDPRMenuItems.vue"
import DeleteMenuItem from "@/components/common/menu-items/DeleteMenuItem.vue";
import EditMenuItem from "@/components/common/menu-items/EditMenuItem.vue";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);

export default Vue.extend({
  props: ['item'],
  computed: {
    agencyAdminRole() {
      return userModule.role == "AGM";
    }
  },
  components: {
    InviteToAppMenuItem,
    GDPRMenuItems,
    DeleteMenuItem,
    EditMenuItem
  },
  methods: {
    handleLoadingChanged(isLoading: boolean) {
      this.$emit('loading', isLoading);
    }
  }
});
